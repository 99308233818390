<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        Advanced Table
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Table</a></li>
          <li class="breadcrumb-item active" aria-current="page">Advanced Table</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Advanced Table</h4>
            <b-row>
              <b-col lg="6" offset="6" class="my-1">
                <b-form-group label="Search" label-for="filter-input" label-cols-sm="3" label-align-sm="right"
                  label-size="sm" class="mb-0">
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search">
                    </b-form-input>
                    <!-- <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                    </b-input-group-append> -->
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-table :filter="filter" :items="items" id="table-list" responsive :per-page="perPage" :current-page="currentPage"
              :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:cell(status)="data">
                <span v-html="data.value"></span>
              </template>
              <template v-slot:cell(action)="data">
                <span v-html="data.value"></span>
              </template>
            </b-table>
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
});

export default {
  data: function () {
    return {
      sortBy: 'name',
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      filter: null,
      sortable: true,
      fields: [
        { key: 'order', sortable: true },
        { key: 'purchasedOn', sortable: true },
        { key: 'customer', sortable: true },
        { key: 'shipTo', sortable: true },
        { key: 'basePrice', sortable: true },
        { key: 'purchasedPrice', sortable: true },
        { key: 'status', sortable: true },
        { key: 'action', sortable: true }
      ],
      items: [
        {
          order: 'XN-1',
          purchasedOn: '25/09/2019',
          customer: 'Edinburgh',
          shipTo: 'New York',
          basePrice: '$1500',
          purchasedPrice: '$3200',
          status: '<label class="badge badge-info">On hold</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
        {
          order: 'XN-2',
          purchasedOn: '26/09/2019',
          customer: 'Doe',
          shipTo: 'Brazil',
          basePrice: '$4500',
          purchasedPrice: '$7500',
          status: '<label class="badge badge-danger">Pending</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
        {
          order: 'XN-3',
          purchasedOn: '26/09/2019',
          customer: 'Sam',
          shipTo: 'Tokyo',
          basePrice: '$2100',
          purchasedPrice: '6300',
          status: '<label class="badge badge-success">success</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
        {
          order: 'XN-4',
          purchasedOn: '27/09/2019',
          customer: 'Joe',
          shipTo: 'Netherland',
          basePrice: '$1100',
          purchasedPrice: '$7300',
          status: '<label class="badge badge-warning">pending</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
        {
          order: 'XN-5',
          purchasedOn: '28/09/2019',
          customer: 'Edward',
          shipTo: 'Indonesia',
          basePrice: '$3600',
          purchasedPrice: '$4800',
          status: '<label class="badge badge-danger">pending</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
        {
          order: 'XN-6',
          purchasedOn: '28/09/2019',
          customer: 'Stella',
          shipTo: 'Japan',
          basePrice: '$5600',
          purchasedPrice: '$3600',
          status: '<label class="badge badge-success">success</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
        {
          order: 'XN-7',
          purchasedOn: '28/09/2019',
          customer: 'Jaqueline',
          shipTo: 'Germany',
          basePrice: '$1100',
          purchasedPrice: '$6300',
          status: '<label class="badge badge-success">success</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
        {
          order: 'XN-8',
          purchasedOn: '29/09/2019',
          customer: 'Tim',
          shipTo: 'Italy',
          basePrice: '$6300',
          purchasedPrice: '$2100',
          status: '<label class="badge badge-info">on hold</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
        {
          order: 'XN-9',
          purchasedOn: '29/09/2019',
          customer: 'John',
          shipTo: 'Tokyo',
          basePrice: '$2100',
          purchasedPrice: '$6300',
          status: '<label class="badge badge-info">on hold</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
        {
          order: 'XN-10',
          purchasedOn: '29/09/2019',
          customer: 'Tom',
          shipTo: 'Germany',
          basePrice: '$1100',
          purchasedPrice: '$2300',
          status: '<label class="badge badge-danger">pending</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
        {
          order: 'XN-11',
          purchasedOn: '30/09/2019',
          customer: 'Aleena',
          shipTo: 'New York',
          basePrice: '$1600',
          purchasedPrice: '$3500',
          status: '<label class="badge badge-danger">pending</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
        {
          order: 'XN-12',
          purchasedOn: '01/10/2019',
          customer: 'Alphy',
          shipTo: 'Brazil',
          basePrice: '$5500',
          purchasedPrice: '$7200',
          status: '<label class="badge badge-danger">pending</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
        {
          order: 'XN-13',
          purchasedOn: '02/10/2019',
          customer: 'Twinkle',
          shipTo: 'Italy',
          basePrice: '$1560',
          purchasedPrice: '$2530',
          status: '<label class="badge badge-success">closed</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
        {
          order: 'XN-14',
          purchasedOn: '02/10/2019',
          customer: 'Catherine',
          shipTo: 'Brazil',
          basePrice: '$1600',
          purchasedPrice: '$5600',
          status: '<label class="badge badge-success">closed</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
        {
          order: 'XN-15',
          purchasedOn: '05/10/2019',
          customer: 'Daniel',
          shipTo: 'Singapore',
          basePrice: '$6800',
          purchasedPrice: '$9800',
          status: '<label class="badge badge-success">closed</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
        {
          order: 'XN-16',
          purchasedOn: '07/10/2019',
          customer: 'Tom',
          shipTo: 'Tokyo',
          basePrice: '$1600',
          purchasedPrice: '$6500',
          status: '<label class="badge badge-info">on hold</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
        {
          order: 'XN-17',
          purchasedOn: '07/10/2019',
          customer: 'Cris',
          shipTo: 'Tokyo',
          basePrice: '$2100',
          purchasedPrice: '$6300',
          status: '<label class="badge badge-info">on hold</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
        {
          order: 'XN-18',
          purchasedOn: '09/10/2019',
          customer: 'Tim',
          shipTo: 'Italy',
          basePrice: '$6300',
          purchasedPrice: '$2100',
          status: '<label class="badge badge-info">on hold</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
        {
          order: 'XN-19',
          purchasedOn: '11/10/2019',
          customer: 'Jack',
          shipTo: 'Tokyo',
          basePrice: '$3100',
          purchasedPrice: '$7300',
          status: '<label class="badge badge-info">on hold</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
        {
          order: 'XN-20',
          purchasedOn: '14/10/2019',
          customer: 'Tony',
          shipTo: 'Germany',
          basePrice: '$1100',
          purchasedPrice: '$2300',
          status: '<label class="badge badge-info">on hold</label>',
          action: '<button class="btn btn-outline-primary px-4 py-1"><i class="mdi mdi-eye-outline text-primary mr-2"></i>View</button>',
        },
      ]
    };
  },
  computed: {
    rows() {
      return this.items.length
    },
    // sortOptions() {
    //     // Create an options list from our fields
    //     return this.fields
    //       .filter(f => f.sortable)
    //       .map(f => {
    //         return { text: f.label, value: f.key }
    //       })
    //   }
  },
  mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
    },
    methods: {
      // info(item, index, button) {
      //   this.infoModal.title = `Row index: ${index}`
      //   this.infoModal.content = JSON.stringify(item, null, 2)
      //   this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      // },
      // resetInfoModal() {
      //   this.infoModal.title = ''
      //   this.infoModal.content = ''
      // },
      // onFiltered(filteredItems) {
      //   // Trigger pagination to update the number of buttons/pages due to filtering
      //   this.totalRows = filteredItems.length
      //   this.currentPage = 1
      // }
    }
}
</script>
